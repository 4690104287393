.button {
  background-color: #ff00fe;
  color: white;
  font-size: 18px;
  font-weight: 400;
  width: 136px;
  height: 42px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Albert Sans";
  font-style: normal;
  font-weight: 700;
  background: #ff00fe;
  box-shadow: inset 0px -5px 1px rgba(0, 0, 0, 0.4);
  border-radius: 8px;
  padding-bottom: 5px;
  text-decoration: none;
}


@media only screen and (max-width: 850px) {
  .button{
    width: 99px;
    height: 34px;
    font-size: 16px;
    box-shadow: inset 0px -3px 1px rgba(0, 0, 0, 0.4);
  }
}


@media only screen and (max-width: 600px) {
  .button img{
    width: 14px !important;
    margin-left: 5px;
  }
}