
.logo{
    display :flex;
    width : fit-content;

 
    display :flex;
    gap  : 10px;
    justify-content : center;
    align-items: center;
    
}
@media only screen and (max-width: 600px) {
    .navItems .logo img{
        width: 62px !important;
        margin-left: 25px;
    }
}