.xythum-privacy-policy {
    background-color: #0a0e17;
    color: #e0e0e0;
    font-family: 'Roboto', sans-serif;
    line-height: 1.6;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    padding: 2rem 0;
  }
  
  .xythum-privacy-policy .container {
    max-width: 800px;
    width: 90%;
    background-color: #141c2f;
    border-radius: 10px;
    padding: 2rem;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
    position: relative;
    overflow: hidden;
  }
  
  .xythum-privacy-policy .container::before {
    content: '';
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    background: linear-gradient(45deg, #64ffda, #0a0e17, #64ffda);
    z-index: -1;
    filter: blur(10px);
    opacity: 0.5;
    border-radius: 12px;
  }
  
  .xythum-privacy-policy h1 {
    color: #64ffda;
    font-size: 2.5rem;
    margin-bottom: 0.5rem;
    text-align: center;
  }
  
  .xythum-privacy-policy .last-updated,
  .xythum-privacy-policy .effective-date {
    text-align: center;
    color: #8892b0;
    margin-bottom: 0.5rem;
  }
  
  .xythum-privacy-policy .intro,
  .xythum-privacy-policy .conclusion {
    margin-bottom: 2rem;
    padding: 1rem;
    background-color: rgba(100, 255, 218, 0.1);
    border-radius: 5px;
  }
  
  .xythum-privacy-policy .accordion {
    margin-bottom: 1rem;
    border: 1px solid #2a3654;
    border-radius: 5px;
    overflow: hidden;
  }
  
  .xythum-privacy-policy .accordion h2 {
    background-color: #1e2a4a;
    color: #64ffda;
    padding: 1rem;
    margin: 0;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .xythum-privacy-policy .accordion h2:hover {
    background-color: #2a3654;
  }
  
  .xythum-privacy-policy .accordion .content {
    padding: 0 1rem;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease, padding 0.3s ease;
  }
  
  .xythum-privacy-policy .accordion.active .content {
    padding: 1rem;
    max-height: 1000px;
  }
  
  .xythum-privacy-policy h4 {
    color: #64ffda;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
  }
  
  .xythum-privacy-policy ul {
    padding-left: 1.5rem;
    margin-bottom: 1rem;
  }
  
  .xythum-privacy-policy li {
    margin-bottom: 0.5rem;
  }
  
  @media (max-width: 600px) {
    .xythum-privacy-policy .container {
      padding: 1.5rem;
    }
  
    .xythum-privacy-policy h1 {
      font-size: 2rem;
    }
  
    .xythum-privacy-policy .accordion h2 {
      font-size: 1.2rem;
    }
  }
  
.sticky-back {
  position: absolute;
  top: 30px;
  left: 30px;
  z-index: 9999;
  border: #8892b0;
  border-radius: 10px;
  padding: 10px;
  background-color: #141c2f;
  cursor: pointer;
  text-decoration: none;
  font-size: larger;
  font: bold;
  color: #e0e0e0;
}

.sticky-back:hover {
  background-color: #8892b0;
  color: #141c2f;
}