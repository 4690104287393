.heading{
margin-bottom: 10px !important;
font-style: normal;
font-weight: 400;
font-family: 'MAZIUS REVIEW 20.09';
}

@media (max-width:1420px) {
   .techStackCard .heading{
    font-size: 20px !important;
   }
}

@media (max-width:680px) {
   .techStackCard .heading{
    font-size: 15px !important;
   }
}