.heroCenter{
    width : 100%;
    
    display :flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
  
}
.lightColor1 {
    width: 400px;
    height: 250px; 
    color: black;
    background: linear-gradient(
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0.201)   
    );

    position: relative;
    top: 70px;
    backdrop-filter: blur(10px); 
}
.lightColor2 {
    width: 0;
    height: 0;
    border-left: 200px solid transparent;
    border-right: 200px solid transparent;
    border-bottom: 250px solid rgb(255, 255, 255);
    position: relative;
    backdrop-filter: blur(30px);
}

.backgroundImage{
    position : absolute;
    top : 90px;
    margin : auto;
  
}
.backgroundImage img{
    position : absolute;
    width  :600px;
    top : 311px;
    margin : auto;
    z-index: 11234512323;
   left : 50%;
   right  : 50%;
   transform : translate(-50% ,-50%);
  
}
.lightImage img{
   
    z-index: -3232;
   width: 1509px;
   height :992px;
    position: absolute;
    top: 90%;
    left: 50%;
    transform: translate(-50%, -50%);


}
.heroCenterHeading{
    font-size: 64px;
   font-family: "Noto Serif Display", serif;font-family: "Noto Serif Display", serif;
   z-index: 323;
    margin-top: 216px;
    font-weight: 700;

}
.heroCenterHeading span{
    color : rgba(92,204,18);
    font-family: "Noto Serif Display", serif;

}
.heroContent {
    font-size: 20px;
    margin-top: 20px;
    position: relative;
    z-index: 9;
    padding: 0 17%;

}
.joinWaitList{
    margin-top: 59px;
}

.lightImage{
    margin-top: -90px;
    position: relative;
}
#learn{
    position: absolute;
    bottom: 200px;
    z-index: -5;
}








.lightImageAnimation {
    width: 300px;
    height: 300px;
    position: absolute;
    top: 600px;
    /* Initial position */
    background-color: white;
    /* White background */
    filter: blur(10px);
    /* Apply blur effect */
    animation: lightningUp 3s linear infinite;
}

@keyframes lightningUp {
    0% {
        transform: translateY(0);
        /* Start at the initial position */
        opacity: 1;
        /* Fully visible at the bottom */
    }

    100% {
        transform: translateY(-100%);
        /* Move it up out of view */
        opacity: 0;
        /* Fade out as it moves up */
    }
}.lightImageAnimation {
    
    width: 700px;
    
    height: 300px;
    position: absolute;
    top: 480px;
   
    background: linear-gradient(to top left, white, rgba(255, 255, 255, 0)70%);
 
    filter: blur(10px);
   
    animation: lightningUp 2s linear infinite;
}

@keyframes lightningUp {
    0% {
        transform: translateY(0);
        /* Start at the initial position */
        opacity: 1;
        /* Fully visible at the bottom */
    }

    100% {
        transform: translateY(2%);
        /* Move it up out of view */
        opacity: 0;
        /* Fade out as it moves up */
    }
}

@media only screen and (max-width: 700px) {
    .heroCenterHeading{
        font-size: 50px;
    }
}


@media only screen and (max-width: 650px) {
    .lightImage video{
        width: 100%;
    }
    .heroCenterHeading {
        margin-top: 115px;
    }
}

@media only screen and (max-width: 600px) {
    .heroContent{
        font-size: 16px;
        text-align: center;
        padding: 0 0%;
    }
    .heading{
        font-size: 25px !important;
    }
    .heroCenterHeading{
        text-align: center;
    }
    .heroCenterHeading span{
        display: block;
    }
    .heroCenterHeading br{
        display: none;
    }
    .heroCenterHeading{
        font-size: 27px;
    }
}
@media only screen and (max-width: 600px) {
    .heroContent{
        font-size: 11px;
    }
}